import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeaderComponent from "../components/page-header";
import BgImage from "../images/misc/bg-callcenter.jpg"
import { Container } from "react-bootstrap";

const AboutPage = () => (
    <Layout >
        <div style={{
            backgroundImage: `url(${BgImage})` ,
            backgroundPosition:`center center`,
            backgroundSize:`cover`
        }}>


            <PageHeaderComponent name="We make the difference" desc="We are making a difference in the Contact Center marketplace through proven customer management solutions. Since we are located in Kosovo, our aim is to offer an outsource services with a reduced labour cost to our partners and keep the same quality of services."/>
        </div>
        <SEO title="About" />
        <section className="page-section pt-0">
            <Container className="text-large">
                <div className="col-md-8">
                    <h1 className="mb-4">About us</h1>
                    <p>Smart Solution LLC specializes in providing flexible, high quality customer support outsourcing solutions with an emphasis on inbound and outbound communication channels encompassing phone, live chat and e-mail.
                    </p>
                    <p>We also provide a full range of IT services to support your business infrastructure outside of your data center, too. Since we are located in Kosovo, our aim is to offer an outsource services with a reduced labour cost to our partners and keep the same quality of services.
                    </p>
                </div>

            </Container>
        </section>
    </Layout>
)

export default AboutPage
